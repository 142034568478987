import React from 'react'
import { 
  
 } from '@mantine/core'


const aboutPage = () => {
return (
  
  
  <div>
   ABOUT PAGE
   </div>
   
    


 
)
}

export default aboutPage